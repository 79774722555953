<template>
  <div
    class="player-panel position-relative d-flex align-items-center text-center"
    :class="[color, {'jungo-player-panel': $isJungo}]"
  >
    <img
      class="avatar rounded-circle mr-1 mr-md-3"
      :srcset="require(`@/assets/img/personal/avatar/${avatar}.png?srcset`)"
    />
    <div class="flex-fill d-flex flex-column">
      <div class="nickname">
        {{ nickname }}
      </div>
      <div :class="{'is-turn': isTurn}" class="time-text rounded-pill">
        {{ timeText }}
      </div>
    </div>
    <div
      class="capture-stone position-absolute left-0 bottom-0 d-flex align-items-center justify-content-center"
    >
      {{ captureStone }}
    </div>
    <div v-show="isTurn" class="dot"></div>

    <!-- 讀秒 -->
    <div
      v-if="sticker && stickerType === 'number'"
      class="number-wrapper position-absolute top-0 left-0 h-100 w-100 text-28 font-weight-bold d-flex align-items-center justify-content-center rounded-10"
    >
      {{ sticker }}
    </div>
    <!-- 表情 -->
    <div v-if="sticker && stickerType === 'sticker'" class="sticker-wrapper">
      <img
        class="sticker img-fluid"
        :src="require('@/assets/img/sticker/' + sticker + '.png?srcset')"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    avatar: {
      type: String,
      default: '0000',
    },
    nickname: {
      type: String,
      default: '',
    },
    timeText: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      validator(value) {
        return ['black', 'white'].includes(value);
      },
      default: 'black',
    },
    captureStone: {
      type: Number,
      default: 0,
    },
    isTurn: {
      type: Boolean,
      default: false,
    },
    countdown: {
      type: Number,
      default: null,
    },
    sticker: {
      type: Number,
      default: null,
    },
    stickerType: {
      type: String,
      validator(value) {
        return ['sticker', 'number'].includes(value);
      },
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.player-panel {
  height: 56px;
  padding: 8px;
  border-radius: $rounded-md;
  @media screen and (min-width: 768px) {
    padding: 10px 16px;
  }
  &.black {
    color: $white;
    background-color: $black-chess;
    .time-text {
      background-color: rgba(0, 0, 0, 0.2);
      &.is-turn {
        background-color: $accent;
      }
    }
    .capture-stone {
      background-color: $black-chess;
    }
  }
  &.white {
    color: $black;
    background-color: $white;
    .time-text {
      background-color: $grayscale-gray;
      color: $font-normal;
      &.is-turn {
        color: $white;
        background-color: $accent;
      }
    }
    .capture-stone {
      background-color: $white;
      color: $font-normal;
    }
  }
  .avatar {
    height: 40px;
    width: 40px;
    @media screen and (min-width: 768px) {
      height: 80px;
      width: 80px;
    }
  }
  .nickname {
    font-size: 16px;
    @media screen and (min-width: 768px) {
      font-size: 20px;
      line-height: 34px;
    }
  }
  .time-text {
    font-size: 12px;
    @media screen and (min-width: 768px) {
      font-size: 16px;
      line-height: 28px;
      padding: 3px 0;
    }
  }
  .capture-stone {
    width: 29px;
    height: 20px;
    border-radius: 11.5px;
    font-size: 12px;
    @media screen and (min-width: 768px) {
      font-size: 16px;
      line-height: 28px;
      width: 46px;
      height: 32px;
    }
  }
  .dot {
    background-color: $accent;
    position: absolute;
    width: 12px;
    height: 12px;
    right: 4px;
    top: 4px;
    border-radius: 50%;
    @media screen and (min-width: 768px) {
      width: 24px;
      height: 24px;
      right: 8px;
      top: 8px;
    }
  }

  .sticker-wrapper {
    position: absolute;
    left: 0;
    top: -12px;
    height: 80px;
    width: 100%;
    background-color: $white;
    border-radius: 10px;
    text-align: center;
    z-index: 100;
    border: 2px solid $primary;
    box-shadow: 0px 2px 7px 0px #c7c7c74d;
    img {
      object-fit: cover;
    }
    @media screen and (min-width: 768px) {
      width: 160px;
      height: 100px;
      top: 0;
      left: 91px;
      border: 4px solid $primary;
      &::after {
        position: absolute;
        content: '';
        top: calc(50% - 5px);
        left: -10px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 10px 8px 0;
        border-color: transparent $primary transparent transparent;
      }
    }
    .sticker {
      height: 100%;
    }
  }
  .number-wrapper {
    background-color: rgba($color: $accent, $alpha: 0.9);
    color: $white;
  }
  &.jungo-player-panel {
    &.black,
    &.white {
      .time-text.is-turn {
        background-color: $accent-2;
      }
    }
    .dot {
      background-color: $accent-2;
    }
    .sticker-wrapper {
      @media screen and (min-width: 768px) {
        width: 150px;
      }
    }
  }
}
</style>
