<template>
  <b-modal
    ref="modal"
    content-class="rounded-10"
    hide-footer
    hide-header
    :visible="value"
    :no-close-on-backdrop="noClose"
    :no-close-on-esc="noClose"
    :centered="true"
    @hide="close"
    @close="close"
  >
    <div
      class="text-center test-16  mb-2 d-flex align-items-center justify-content-center"
      :class="contentHeight ? 'mt-2' : 'mt-4'"
      :style="`height: ${contentHeight}px`"
    >
      <slot name="content"></slot>
    </div>
    <div class="mb-3 d-flex justify-content-center">
      <b-progress
        height="2px"
        :value="percentage"
        style="width: 200px"
      ></b-progress>
    </div>
    <div class="text-center mb-2">
      <slot name="button"></slot>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    sec: {
      type: Number,
      default: 10,
    },
    noClose: {
      type: Boolean,
      default: true,
    },
    contentHeight: {
      type: Number,
      default: 60,
    },
  },
  data() {
    return {
      nowSec: 0,
      totalSec: 10,
      timer: null,
    };
  },
  computed: {
    percentage() {
      return Math.ceil((this.nowSec / this.totalSec) * 100);
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.startTimer();
        } else {
          this.close();
        }
      },
    },
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  methods: {
    startTimer() {
      this.nowSec = this.sec;
      this.$emit('updateNowSec', this.nowSec);
      this.totalSec = this.sec;
      this.timer = setInterval(() => {
        if (this.nowSec === 0) {
          this.close();
        } else {
          this.nowSec -= 1;
          this.$emit('updateNowSec', this.nowSec);
        }
      }, 1000);
    },
    close() {
      clearInterval(this.timer);
      this.$emit('input', false);
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .modal-content {
  width: 300px;
  margin: 0 auto;
}
</style>
