import socket from '@/lib/socket/socket.js';

const mixinObject = {
  data() {
    return {
      _matchAiTimeout: null,
      pvpDefaultAiUser: {
        username: 'AI_USERNAME',
        label: 'AI',
        status: 'online',
      },
    };
  },
  computed: {
    userDetails() {
      return this.$store.getters['user/userData'];
    },
  },
  methods: {
    pvpMatchAiAfterTimeout() {
      if (!this._pvpMatchAiConditionCheck()) {
        return false;
      }
      const randomSec = Math.floor(Math.random() * 20) + 40;
      this._matchAiTimeout = setTimeout(() => {
        this._pvpMatchAi();
      }, 1000 * randomSec);
    },
    cancelPvpMatchAiTimeout() {
      clearTimeout(this._matchAiTimeout);
    },
    _pvpMatchAi() {
      socket.send({
        action: 'pvp',
        pvpAction: socket.SendEventEnum.PVP_CREATE_AI_GAME,
      });
    },
    _pvpMatchAiConditionCheck() {
      const user = {
        currentPlanPermissions: [
          this.$store.getters['user/hasPermission']('pvpAiMatch')
            ? 'pvpAiMatch'
            : '',
        ],
        isIntelligentVersionActive:
          this.$store.getters['user/hasLegacyAdvancedPlanActivated'],
        rank: this.userDetails.rank,
      };
      return this._pvpUserMatchAiConditionCheck({
        user,
        boardSize: this.boardSize,
      });
    },
    _pvpUserMatchAiConditionCheck({user, boardSize}) {
      const hasPermission =
        user.currentPlanPermissions.includes('pvpAiMatch') ||
        user.isIntelligentVersionActive;
      const conditionRank = this._getConditionRank({boardSize});
      const conditions = user.rank <= conditionRank;
      return hasPermission && conditions;
    },
    _getConditionRank({boardSize}) {
      const matchConditions = this._getMatchConditions();
      const rank = matchConditions[boardSize];
      return rank;
    },
    _getMatchConditions() {
      return {
        19: 33,
        13: 20,
        9: 10,
      };
    },
  },
  beforeDestroy() {
    this.cancelPvpMatchAiTimeout();
  },
};

export default mixinObject;
