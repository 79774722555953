<template>
  <div class="versus w-100 d-flex text-16" :class="{'jungo-versus': $isJungo}">
    <div
      class="left d-flex align-items-center justify-content-center flex-column"
    >
      <img
        class="avatar rounded-circle"
        :srcset="require(`@/assets/img/personal/avatar/${avatar}.png?srcset`)"
      />
      <div>{{ nickname }}</div>
    </div>
    <div class="center d-flex position-relative">
      <div class="trapezoid left-trapezoid"></div>
      <img
        :srcset="require('@/assets/img/common/versus.png?srcset')"
        class="versus-icon position-absolute"
      />
      <div class="trapezoid right-trapezoid"></div>
    </div>
    <div
      class="right d-flex align-items-center justify-content-center flex-column"
    >
      <img
        class="avatar rounded-circle"
        :srcset="require('@/assets/img/common/default-avatar.png?srcset')"
      />
      <div>{{ $t('等待好友') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    avatar: {
      type: String,
      default: '0000',
    },
    nickname: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.versus {
  height: 80px;
  color: $white;
  .avatar {
    height: 40px;
    width: 40px;
  }
  .left {
    background-color: $primary;
    border-radius: 10px 0 0 10px;
    flex: 1;
  }
  .center {
    width: 42px;
    margin: 0 -1px;
    .trapezoid {
      position: absolute;
      height: 0;
      width: 70%;
    }
    .left-trapezoid {
      left: 0;
      border-bottom: 80px solid $primary;
      border-right: 20px solid transparent;
    }
    .right-trapezoid {
      right: 0;
      border-top: 80px solid $secondary;
      border-left: 20px solid transparent;
    }
    .versus-icon {
      width: 42px;
      height: 42px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 5;
    }
  }
  .right {
    background-color: $secondary;
    border-radius: 0 10px 10px 0;
    flex: 1;
  }
  &.jungo-versus {
    .left {
      background-color: $cyan-blue;
    }
    .right {
      background-color: $font-secondary;
    }
    .center {
      .left-trapezoid {
        border-bottom-color: $cyan-blue;
      }
      .right-trapezoid {
        border-top-color: $font-secondary;
      }
    }
  }
}
</style>
