<template>
  <div class="d-md-flex flex-md-column justify-content-md-between h-100">
    <div class="wrap-quick-match-list">
      <template v-for="option in quickMatchOptions">
        <QuickMatchOptionCard
          :key="option.value"
          :option="option"
          :is-selected="selectedQuickMatchOption === option.value"
          @item-click="onItemClick"
        ></QuickMatchOptionCard>
      </template>
    </div>
    <b-button
      v-if="!$device.isMobile"
      class="nowrap font-weight-bold w-100"
      variant="primary"
      size="lg"
      @click="$emit('get-quick-match')"
      >{{ $t('開始') }}</b-button
    >
  </div>
</template>

<script>
import QuickMatchOptionCard from '@/components/Pvp/QuickMatchOptionCard.vue';

export default {
  components: {
    QuickMatchOptionCard,
  },
  props: {
    selectedQuickMatchOption: {
      type: String,
      default: 'noHandicap9',
    },
  },
  data() {
    return {
      quickMatchOptions: [
        {
          value: 'noHandicap9',
          title: this.$t('{v1}路  - {v2} 分鐘', {
            v1: 9,
            v2: 5,
          }),
          subTitle: this.$t('{v3}秒{v4}次', {
            v3: 20,
            v4: 3,
          }),
          hasHandicap: false,
        },
        {
          value: 'noHandicap13',
          title: this.$t('{v1}路  - {v2} 分鐘', {
            v1: 13,
            v2: 10,
          }),
          subTitle: this.$t('{v3}秒{v4}次', {
            v3: 20,
            v4: 3,
          }),
          hasHandicap: false,
        },
        {
          value: 'noHandicap19',
          title: this.$t('{v1}路  - {v2} 分鐘', {
            v1: 19,
            v2: 20,
          }),
          subTitle: this.$t('{v3}秒{v4}次', {
            v3: 30,
            v4: 3,
          }),
          hasHandicap: false,
        },
      ],
    };
  },
  methods: {
    onItemClick(option) {
      this.$emit('item-click', option);
    },
  },
};
</script>

<style lang="scss" scoped></style>
