<template>
  <b-card
    border-variant="white"
    class="quick-match-option-card card-shadow position-relative cursor-pointer"
    @click.stop="onItemClick(option.value)"
  >
    <div class="d-flex flex-nowrap justify-content-between align-items-center">
      <div class="d-flex flex-column">
        <p class="quick-match-option text-20 font-weight-bold">
          {{ `${option.title}` }}
        </p>
        <p class="condition m-0">
          {{ option.subTitle }} -
          {{ option.hasHandicap ? $t('讓子') : $t('不讓子') }}
        </p>
      </div>
      <div class="wrap-icon-correct">
        <span v-if="isSelected" class="icon-Colorful-correct">
          <span class="path1"></span>
          <span class="path2"></span>
        </span>
        <span v-else class="icon-Colorful-correct-gray">
          <span class="path1"></span>
          <span class="path2"></span>
        </span>
      </div>
    </div>
  </b-card>
</template>

<script>
export default {
  props: {
    option: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onItemClick(option) {
      this.$emit('item-click', option);
    },
  },
};
</script>

<style lang="scss" scoped>
.quick-match-option-card {
  padding: 16px 20px 16px 16px;
  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
  @media screen and (min-width: 768px) {
    border: 4px solid white;
    &:hover {
      border: 4px solid #ced4da !important;
    }
  }
  .quick-match-option {
    color: $font-grayscale-1;
    line-height: 34px;
    margin: 0;
  }
}
.condition {
  color: $font-grayscale-1;
  font-size: 14px;
  line-height: 22px;
}
.btn-sm {
  min-width: 65px;
}
.wrap-icon-correct {
  height: 36px;
}
.icon-Colorful-correct,
.icon-Colorful-correct-gray {
  font-size: 36px;
  position: relative;
}
.icon-Colorful-correct-gray .path2,
.icon-Colorful-correct .path2,
.icon-Colorful-error .path2 {
  position: absolute;
  top: 0;
  left: 0;
}
.icon-Colorful-correct-gray .path2::before,
.icon-Colorful-correct .path2::before,
.icon-Colorful-error .path2::before {
  margin-left: 0;
}
</style>
