<template>
  <div class="pvp overflow-auto h-100 w-100 d-flex flex-column">
    <base-header
      :title="$isJungo ? $t('好友對戰') : $t('對戰')"
      :is-title-bold="true"
      :back-on-click-left="false"
      :left-arrow="$device.isMobile"
      :right-icon="'Setting'"
      :left-text="this.$device.isMobile ? '' : $t('返回首頁')"
      @on-click-left="leave"
      @on-click-right="goSetting"
    ></base-header>
    <div
      class="p-3 p-md-4"
      style="flex: 1"
      :class="{invisible: !isLeave && $device.isMobile}"
    >
      <div
        class="wrap-pvp position-relative d-md-flex justify-content-md-center"
      >
        <div v-if="!isScorePanelShow && $device.isMobile" class="d-flex mb-2">
          <player-panel
            :avatar="blackPlayer.avatar"
            :nickname="blackPlayer.name"
            :time-text="blackTimeText"
            :capture-stone="boardCount.blackBoardCount"
            :is-turn="turnColor === 'black'"
            :sticker="blackSticker"
            :sticker-type="blackStickerType"
            color="black"
            class="mr-1"
            style="flex: 1"
          ></player-panel>
          <player-panel
            :avatar="whitePlayer.avatar"
            :nickname="whitePlayer.name"
            :time-text="whiteTimeText"
            :capture-stone="boardCount.whiteBoardCount"
            :is-turn="turnColor === 'white'"
            :sticker="whiteSticker"
            :sticker-type="whiteStickerType"
            color="white"
            style="flex: 1"
          ></player-panel>
        </div>
        <Chessboard
          ref="chessboard"
          :key="chessboardKey"
          mode="pvp"
          :is-locked="isPlayerLocked"
          :board-size="boardSize"
          :sgf="sgf"
          :hide-tool-bar="isScorePanelShow || !$device.isMobile"
          :has-judge="!$isJungo"
          toolbar="pvp"
          @board-grid-change="onBoardGridChange"
          @sticker-select="sendSticker"
          @resign="resign"
          @judge="inviteJudgement"
          @cap-count-change="onCapCountChange"
          @turn-color-change="onTurnColorChange"
          @step-type-change="onStepTypeChange"
          @current-step-change="onCurrentStepChange"
          @max-step-change="onMaxStepChange"
          @chessboard-toolbar-mode-change="onChessboardToolbarModeChange"
        ></Chessboard>
        <div v-if="!$device.isMobile" class="wrap-right h-100">
          <div
            class="wrap-operate-panel ml-md-3 position-relative"
            :class="{'bg-white': !$device.isMobile}"
          >
            <div
              v-if="isWebPvpHome"
              class="operate-panel d-md-flex flex-md-column"
            >
              <!-- // 快速配對邏輯需調整，先做隱藏，預設和朋友下棋 -->
              <div v-if="!$isJungo" class="wrap-btn-tab d-flex p-md-2">
                <b-button
                  class="nowrap mr-2 font-weight-bold px-md-0"
                  :variant="
                    isPvpHomeTab === 'quickMatch' ? 'secondary' : 'white'
                  "
                  size="lg"
                  @click="isPvpHomeTab = 'quickMatch'"
                  >{{ $t('快速配對') }}</b-button
                >
                <b-button
                  class="nowrap font-weight-bold px-md-0"
                  :variant="
                    isPvpHomeTab === 'createPvp' ? 'secondary' : 'white'
                  "
                  size="lg"
                  @click="isPvpHomeTab = 'createPvp'"
                  >{{ $t('和朋友下棋') }}</b-button
                >
              </div>
              <div
                class="d-flex flex-column justify-content-between h-100 px-2 pb-2"
              >
                <QuickMatchList
                  v-if="isPvpHomeTab === 'quickMatch'"
                  :selected-quick-match-option="selectedQuickMatchOption"
                  @item-click="onQuickMatchOptionClick"
                  @get-quick-match="getQuickMatch"
                ></QuickMatchList>
                <CreatePvpMenu
                  v-if="isPvpHomeTab === 'createPvp'"
                  @is-pvp-wait="onPvpWait"
                  @board-size-change="onBoardSizeChange"
                ></CreatePvpMenu>
              </div>
            </div>
            <div
              v-else-if="isPvpWait"
              class="operate-panel d-md-flex flex-md-column"
            >
              <PvpWait :id="lobbyId" @pvp-wait-close="onWebPvpHome"></PvpWait>
            </div>
            <div
              v-else
              class="operate-panel d-flex flex-column justify-content-md-between"
            >
              <div>
                <div
                  class="wrap-victory-condition text-center text-white bg-secondary"
                  :class="$isJungo ? 'px-3' : 'text-18 font-weight-bold'"
                >
                  <template v-if="$isJungo"
                    >盤上にある石を数え、多いほうが勝ちとなります。
                  </template>
                  <template v-else>
                    {{ $t('勝利條件') + ' : ' }}
                    <span>{{
                      $t('黑') + victoryCondition[boardSize] + $t('子')
                    }}</span>
                  </template>
                </div>
                <div class="d-flex flex-column mb-2 p-2">
                  <player-panel
                    :avatar="blackPlayer.avatar"
                    :nickname="blackPlayer.name"
                    :time-text="blackTimeText"
                    :capture-stone="boardCount.blackBoardCount"
                    :is-turn="turnColor === 'black'"
                    :sticker="blackSticker"
                    :sticker-type="blackStickerType"
                    color="black"
                    class="mr-1 mb-2"
                    style="flex: 1"
                  ></player-panel>
                  <player-panel
                    :avatar="whitePlayer.avatar"
                    :nickname="whitePlayer.name"
                    :time-text="whiteTimeText"
                    :capture-stone="boardCount.whiteBoardCount"
                    :is-turn="turnColor === 'white'"
                    :sticker="whiteSticker"
                    :sticker-type="whiteStickerType"
                    color="white"
                    style="flex: 1"
                  ></player-panel>
                </div>
              </div>
              <ChessboardToolbar
                v-if="!isScorePanelShow"
                :mode="chessboardToolbarMode"
                :step-type="stepType"
                :current-step="currentStep"
                :max-step="maxStep"
                :has-judge="!$isJungo"
                @item-click="onToolbarClick"
                @move-chess="moveChess"
                @check-answer-tab="checkAnswerTab"
                @step-change="stepChange"
                @sticker-select="sendSticker"
              ></ChessboardToolbar>
              <panel-timer
                v-if="isScorePanelShow"
                class="m-2"
                :sec="10"
                @close="refuseJudgeResult"
              >
                <template v-slot:content>
                  {{ winningWayText }}
                </template>
                <template v-slot:button>
                  <span v-if="!isJudgeConfirm">
                    <b-button size="md mr-3" @click="refuseJudgeResult">{{
                      $t('取消')
                    }}</b-button>
                    <b-button
                      size="md"
                      variant="primary"
                      @click="acceptJudgeResult"
                      >{{ $t('確定') }}</b-button
                    >
                  </span>
                  <span v-if="isJudgeConfirm" class="remark text-16">
                    {{ $t('( 你已確認結果，對方仍在確認中 )') }}
                  </span>
                </template>
              </panel-timer>
            </div>
          </div>
        </div>
        <panel-timer
          v-if="isScorePanelShow && $device.isMobile"
          class="mt-2"
          :sec="20"
          @close="refuseJudgeResult"
        >
          <template v-slot:content>
            {{ winningWayText }}
          </template>
          <template v-slot:button>
            <span v-if="!isJudgeConfirm">
              <b-button
                size="md"
                :variant="$isJungo ? 'primary' : 'secondary'"
                class="mr-3"
                @click="refuseJudgeResult"
                >{{ $t('取消') }}</b-button
              >
              <b-button
                size="md"
                :variant="$isJungo ? 'secondary' : 'primary'"
                @click="acceptJudgeResult"
                >{{ $t('確定') }}</b-button
              >
            </span>
            <span v-if="isJudgeConfirm" class="remark text-16">
              {{ $t('( 你已確認結果，對方仍在確認中 )') }}
            </span>
          </template>
        </panel-timer>
      </div>
    </div>

    <!-- 對局結果modal -->
    <modal-result
      v-if="['win', 'lose'].includes(modal)"
      mode="pvp"
      :is-success="game.winner === userColor"
      :winning-way-text="winningWayText"
      :has-reviewed="completeStep >= 20"
      @item-click="onResultModalAction"
    ></modal-result>

    <!-- 對方詢問是否同意算輸贏modal -->
    <modal-timer v-if="modal === 'judge'" :sec="10" @close="refuseJudge">
      <template v-slot:content>
        {{ $t('對方想要申請算輸贏') }}<br />
        {{ $t('是否同意？') }}
      </template>
      <template v-slot:button>
        <b-button
          :variant="$isJungo ? 'primary' : 'secondary'"
          size="md mr-3"
          @click="refuseJudge"
          >{{ $t('拒絕') }}</b-button
        >
        <b-button
          size="md"
          :variant="$isJungo ? 'secondary' : 'primary'"
          @click="acceptJudge"
          >{{ $t('同意') }}</b-button
        >
      </template>
    </modal-timer>

    <!-- 對方詢問是否再來一局modal -->
    <modal-timer v-if="modal === 'rematch'" :sec="10" @close="refuseRematch">
      <template v-slot:content>
        {{ $t('對方想要再一局') }}<br />
        {{ $t('是否同意？') }}
      </template>
      <template v-slot:button>
        <b-button
          size="md"
          :variant="$isJungo ? 'primary' : 'secondary'"
          class="mr-3"
          @click="refuseRematch"
          >{{ $t('拒絕') }}</b-button
        >
        <b-button
          size="md"
          :variant="$isJungo ? 'secondary' : 'primary'"
          @click="acceptRematch"
          >{{ $t('同意') }}</b-button
        >
      </template>
    </modal-timer>

    <!-- 等待對方回應算輸贏申請modal -->
    <modal-timer
      v-if="modal === 'judgeWait'"
      :sec="10"
      @close="onJudgeWaitTimeout"
    >
      <template v-slot:content>
        {{ $t('正在等待對方回應') }}
      </template>
    </modal-timer>

    <!-- 等待對方回應再來一局申請modal -->
    <modal-timer
      v-if="modal === 'rematchWait'"
      :sec="10"
      @close="modal = 'refuseRematch'"
    >
      <template v-slot:content>
        {{ $t('正在等待對方回應') }}
      </template>
    </modal-timer>

    <!-- web版等待好友點擊返回首頁modal -->
    <modal-confirm
      v-if="modal === 'waitLeave'"
      @cancel="cancelConfirm"
      @confirm="waitLeave"
    >
      <template>
        {{ $t('確定要離開對局？') }}
      </template>
    </modal-confirm>

    <!-- 等待對方拒絕算輸贏modal -->
    <modal-confirm
      v-if="modal === 'leave'"
      @cancel="cancelConfirm"
      @confirm="resignAndLeave"
    >
      <template>
        {{ $t('離開將自動判輸') }}<br />
        {{ $t('確定要離開嗎？') }}
      </template>
    </modal-confirm>

    <!-- 等待對方拒絕算輸贏modal -->
    <modal-confirm
      v-if="modal === 'refuseJudge'"
      :hide-cancel="true"
      @confirm="refuse2Judge"
    >
      <template>
        {{ $t('對方拒絕算輸贏，請繼續對局') }}
      </template>
    </modal-confirm>

    <!-- 等待對方拒絕再來一局modal -->
    <modal-confirm
      v-if="modal === 'refuseRematch'"
      :hide-cancel="true"
      @confirm="onModalLeave"
    >
      <template>
        {{ $t('對方已婉拒或離開房間') }}
      </template>
    </modal-confirm>

    <!-- 等待快速配對modal -->
    <modal-timer
      v-if="isQuickMatchShow"
      :sec="60"
      :content-height="175"
      @updateNowSec="updateNowSec"
      @close="isNoMatchShow = true"
    >
      <template v-slot:content>
        <div class="content">
          <img class="img-user-circle" :src="ImageUserCircle" />
          <p class="mt-2 mb-0 text-18 modal-title font-weight-bold">
            {{ $t('尋找對手中') }}
          </p>
          <p class="mt-3 mb-0">{{ nowSec + $t('秒') }}</p>
        </div>
      </template>
      <template v-slot:button>
        <b-button
          variant="primary"
          size="md"
          class="w-100"
          @click="closeQuickMatch"
          >{{ $t('取消') }}</b-button
        >
      </template>
    </modal-timer>

    <!-- 找不到對手modal -->
    <modal-confirm
      v-if="isNoMatchShow"
      :hide-cancel="true"
      :confirm-text="$t('知道了')"
      :has-btn-close="true"
      @confirm="closeQuickMatch"
    >
      <template>
        {{ $t('找不到對手') }}<br />
        {{ $t('配對已取消') }}
      </template>
    </modal-confirm>

    <!-- 過場動畫 mobile/web -->
    <pass-animation
      v-if="isPassAnimation && $device.isMobile"
      :leave="isLeave"
      :black="blackPlayer"
      :white="whitePlayer"
      @animation-end="destoryPassAnimation"
    ></pass-animation>
    <WebPassAnimation
      v-if="isPassAnimation && !$device.isMobile"
      :leave="isLeave"
      :black="blackPlayer"
      :white="whitePlayer"
      @animation-end="destoryPassAnimation"
    ></WebPassAnimation>

    <!-- web版header右上設定 -->
    <modal-wrapper
      v-if="isSettingShow && !$device.isMobile"
      :is-modal-wrapper-show="isSettingShow"
      :is-show-transition="true"
      :click-to-close="true"
      @close="isSettingShow = false"
    >
      <Setting
        v-if="isSettingShow"
        @close="isSettingShow = false"
        @click.native.stop=""
      ></Setting>
    </modal-wrapper>
  </div>
</template>

<script>
import _ from 'lodash';
import {leela as leelaUtils, sgf as sgfUtils} from 'goer-utils';
import PvpConfig from '@/json/pvp.json';
import errorMapping from '@/constant/error';
import BaseHeader from '@/components/Base/BaseHeader';
import Chessboard from '@/components/Chessboard/Chessboard.vue';
import PlayerPanel from '@/components/Game/PlayerPanel.vue';
import ModalResult from '@/components/Modal/ModalResult.vue';
import ModalTimer from '@/components/Modal/ModalTimer.vue';
import ModalConfirm from '@/components/Modal/ModalConfirm.vue';
import PanelTimer from '@/components/Base/PanelTimer.vue';
import PassAnimation from '@/components/Base/PassAnimation.vue';
import WebPassAnimation from '@/components/Base/WebPassAnimation.vue';
import socket from '@/lib/socket/socket.js';
import filters from '@/lib/base/filters.js';
import BackEvent from '@/lib/base/backEvent.js';
import QuickMatchList from '@/components/Pvp/QuickMatchList.vue';
import CreatePvpMenu from '@/views/Pvp/PvpMenu.vue';
import PvpWait from '@/views/Pvp/PvpWait.vue';
import ModalWrapper from '@/components/Base/ModalWrapper.vue';
import Setting from '@/views/Personal/RoomSetting.vue';
import {aiGame} from '@/constant/env';
import ChessboardToolbar from '@/components/Chessboard/ChessboardToolbar.vue';
import pvpEnterLobbyMixin from '@/mixin/pvpEnterLobby';
import pvpQuickMatchMixin from '@/mixin/pvpQuickMatch';
import pvpMatchAiMixin from '@/mixin/pvpMatchAi';

export default {
  name: 'Pvp',
  components: {
    BaseHeader,
    Chessboard,
    PlayerPanel,
    ModalResult,
    ModalTimer,
    ModalConfirm,
    PanelTimer,
    PassAnimation,
    WebPassAnimation,
    QuickMatchList,
    CreatePvpMenu,
    PvpWait,
    ModalWrapper,
    Setting,
    ChessboardToolbar,
  },
  mixins: [pvpEnterLobbyMixin, pvpQuickMatchMixin, pvpMatchAiMixin],
  data() {
    return {
      isPassAnimation: false,
      isLeave: false,
      sgf: null,
      winningWayText: '', // 對局結果文字
      isJudgeConfirm: false, // 是否已確認計地結果
      isLastAmountSoundPlaying: false, // 是否正在播放最後一次讀秒音效
      isScorePanelShow: false,
      // 以上為嘉嘉人人對弈新增屬性
      pvpGameHelper: null,
      isPlayerLocked: true, // 是否鎖住棋盤點擊
      isJudgeable: true, // 是否可以算輸贏
      shouldLeaveConfirm: true, // 是否離開router時跳出confirm
      modal: '', // 各種modal resign or
      boardSize: 7, // 棋盤大小
      userColor: null, // 使用者執黑執白或觀戰 black or white or null
      turnColor: 'white', // 現在輪到哪個顏色 black or white
      capCount: {
        // 提子數量
        black: 0,
        white: 0,
      },
      boardCount: {
        blackBoardCount: 0,
        whiteBoardCount: 0,
      },
      game: {
        isPaused: false,
        pausedUsername: '',
        pausedRealName: '',
        winner: null,
        isJudgementAllowed: false,
        isCreatedByThirdParty: false,
      }, // 對局document
      blackPlayer: {
        // 黑棋棋手資訊
        avatar: '0000',
        name: '',
        rank: null,
      },
      whitePlayer: {
        // 白棋棋手資訊
        avatar: '0000',
        name: '',
        rank: null,
      },
      checkOverTimeTimer: null, // 幫對手判定超時setTimeout的timer
      judgeWaitTimeoutTimer: null, // 申請算輸贏的緩衝timer
      blackTimer: null, // 黑棋時間setInterval的timer
      whiteTimer: null, // 白棋時間setInterval的timer
      blackStickerTimer: null, // 黑棋貼圖setTimeout的timer
      whiteStickerTimer: null, // 白棋貼圖setTimeout的timer
      blackTimeText: '00:00', // 黑棋時間文字顯示
      whiteTimeText: '00:00', // 白棋時間文字顯示
      blackSticker: null, // 黑棋貼圖id
      whiteSticker: null, // 白棋貼圖id
      blackStickerType: 'sticker', // 黑棋貼圖類型 sticker or number
      whiteStickerType: 'sticker', // 白棋貼圖類型 sticker or number
      blackTime: {
        // 黑棋時間
        mainTime: 0,
        byoYomiTime: 0,
        byoYomiAmount: 0,
      },
      whiteTime: {
        // 白棋時間
        mainTime: 0,
        byoYomiTime: 0,
        byoYomiAmount: 0,
      },
      step: 0,
      completeStep: 10,
      lastStickerTime: null, // 使用者最後發送貼圖的時間
      doubleCheckPosition: null, // 落子double check資訊
      offlineMessage: null, // 斷線message提示object
      isSettingShow: false,
      chessboardKey: 0, // 快速配對及和朋友下棋切換棋盤路數時更新棋盤
      isWebPvpHome: false, // web版對戰首頁（快速配對、和朋友下棋列表）
      isPvpHomeTab: 'quickMatch', // 切換快速配對/和朋友下棋
      selectedQuickMatchOption: 'noHandicap9',
      time: 5, // 快速配對時間
      byoYomiTime: 20, // 快速配對到計時秒數
      byoYomiAmount: 3, // 快速配對到計時次數
      hasHandicap: false, // 快速配對是否讓子
      lobbyId: null, // web版創建房間或是快速配對的大廳id
      isPvpWait: false, // web版和朋友下棋創建房間後等待朋友加入
      victoryCondition: aiGame.victoryCondition,
      stepType: '', // web版棋盤工具手數顯示
      currentStep: 0,
      maxStep: 0,
      chessboardToolbarMode: 'pvp',
    };
  },
  computed: {
    laoziSetting() {
      return (
        this.$store.getters['user/userData'].config.laoziSetting === 'confirm'
      );
    },
    userDetails() {
      return this.$store.getters['user/userData'];
    },
    isLoading() {
      return this.$store.getters['env/isLoading'];
    },
  },
  watch: {
    blackSticker(value) {
      if (value != null && this.blackStickerType === 'sticker') {
        clearTimeout(this.blackStickerTimer);
        this.blackStickerTimer = setTimeout(() => {
          this.blackSticker = null;
        }, 1000 * PvpConfig.stickerDuration);
      }
    },
    whiteSticker(value) {
      if (value != null && this.whiteStickerType === 'sticker') {
        clearTimeout(this.whiteStickerTimer);
        this.whiteStickerTimer = setTimeout(() => {
          this.whiteSticker = null;
        }, 1000 * PvpConfig.stickerDuration);
      }
    },
    $route: {
      handler() {
        if (this.$route.name === 'pvp') {
          BackEvent.clearEvents();
          BackEvent.addEvent(() => {
            this.leave();
          });
        }
      },
      immediate: true,
    },
    selectedQuickMatchOption(newValue) {
      switch (newValue) {
        case 'noHandicap9':
          this.time = 5;
          this.byoYomiTime = 20;
          this.hasHandicap = false;
          this.onBoardSizeChange(9);
          break;
        case 'noHandicap13':
          this.time = 10;
          this.byoYomiTime = 20;
          this.hasHandicap = false;
          this.onBoardSizeChange(13);
          break;
        case 'noHandicap19':
          this.time = 20;
          this.byoYomiTime = 30;
          this.hasHandicap = false;
          this.onBoardSizeChange(19);
          break;
      }
    },
  },
  created() {
    this.$store.commit('env/setIsLoading', true);
    this.lastStickerTime = localStorage.getItem('lastStickerTime');
    if (!this.$device.isMobile) this.isWebPvpHome = true;
    this.initialGame();
    if (this.$isJungo) {
      this.isPvpHomeTab = 'createPvp';
    }
  },
  destroyed() {
    this.$store.commit('env/setIsLoading', false);
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'roomSetting' || !this.game || this.isWebPvpHome) {
      next();
    } else if (
      !this.shouldLeaveConfirm ||
      !this.game ||
      this.game.winner ||
      confirm(this.$t('對局仍在進行中，確定要離開嗎？'))
    ) {
      this.clearSocketEvent();
      if (to.name !== 'roomSetting') {
        const key =
          this.$vnode.key == null
            ? this.$vnode.componentOptions.Ctor.cid +
              (this.$vnode.componentOptions.tag
                ? `::${this.$vnode.componentOptions.tag}`
                : '')
            : this.$vnode.key;
        const cache = this.$vnode.parent.componentInstance.cache;
        const keys = this.$vnode.parent.componentInstance.keys;
        if (cache[key]) {
          if (keys.length) {
            const index = keys.indexOf(key);
            if (index > -1) {
              keys.splice(index, 1);
            }
          }
          delete cache[key];
        }
        this.$destroy();
      }
      next();
    } else {
      next(false);
    }
  },
  methods: {
    onBoardGridChange(boardGrid) {
      let blackBoardCount = 0;
      let whiteBoardCount = 0;
      const arr = _.flatten(boardGrid);
      arr.forEach((value) => {
        if (value === 1) {
          blackBoardCount += 1;
        } else if (value === -1) {
          whiteBoardCount += 1;
        }
      });
      this.boardCount.blackBoardCount = blackBoardCount;
      this.boardCount.whiteBoardCount = whiteBoardCount;
    },
    setTurn() {
      this.isPlayerLocked = this.turnColor !== this.userColor;
    },
    // 初始化棋盤player
    async initialPlayer(gameData) {
      if (gameData.black === this.userDetails.username) {
        this.userColor = 'black';
      } else if (gameData.white === this.userDetails.username) {
        this.userColor = 'white';
      }
      const sgf = gameData.sgf || `(;CA[big5]SZ[${this.boardSize}])`;
      if (!this.pvpGameHelper) {
        this.pvpGameHelper = this.$refs.chessboard.wgoHelper;
        this.pvpGameHelper.on('playerLock', (isLocked) => {
          this.isPlayerLocked = isLocked;
        });
        this.pvpGameHelper.on('update', () => {
          this.step = this.pvpGameHelper.step;
          this.completeStep = this.pvpGameHelper.completeStep;
          this.capCount = this.pvpGameHelper.captureCount;
        });
        this.pvpGameHelper.on('play', ({move, color}) => {
          this.setTurn();
          // 如果是自己落子
          if (color === this.userColor) {
            this.pvpGameHelper.clearInfluenceMarkup();
            const timeObject = this[color + 'Time'];
            // 棋盤只有一子的情況下，wgoHelper無法區分是新落子，還是棋盤狀態更新
            // 所以加上game.sgf的比對，若一致則視同棋盤狀態更新，不發送落子event
            if (
              sgfUtils.standardizeSgf(this.pvpGameHelper.sgf) === this.game.sgf
            ) {
              return;
            }
            socket.send({
              action: 'pvp',
              pvpAction: socket.SendEventEnum.PVP_MOVE,
              data: {
                gameId: this.game.gameId,
                move,
                time: timeObject.mainTime,
                byoYomiTime: timeObject.byoYomiTime,
                byoYomiAmount: timeObject.byoYomiAmount,
              },
            });
          }
        });
        this.pvpGameHelper.on('addStone', () => {
          this.$playSound('play');
          this.isLastAmountSoundPlaying = false;

          this.turnColor = this.pvpGameHelper.turnColor; // 落子後的turnColor
          this.runTimer();
          if (this.blackStickerType === 'number') {
            this.blackSticker = null;
            this.blackStickerType = 'sticker';
          }
          if (this.whiteStickerType === 'number') {
            this.whiteSticker = null;
            this.whiteStickerType = 'sticker';
          }
        });
      }
      this.pvpGameHelper.sgf = sgf;
      this.capCount = this.pvpGameHelper.captureCount;
      this.step = this.pvpGameHelper.step;
      this.completeStep = this.pvpGameHelper.completeStep;
      this.pvpGameHelper.setEditable(true);

      await this.untilNextTick();
      if (this.pvpGameHelper.step === 0 && sgf.includes('AB[')) {
        this.pvpGameHelper.editable.player.kifuReader.game.turn = -1;
      }
      this.turnColor = this.pvpGameHelper.turnColor;
      this.setTurn();
    },
    async initialGame() {
      this.clearSocketEvent();
      const sendEvent = socket.SendEventEnum.PVP_GET_UNFINISHED_GAME;
      socket.send({
        action: 'pvp',
        pvpAction: sendEvent,
      });
      const getGameResponseEvent = socket.ResponseEventEnum.PVP_EXIST_PVP_GAME;
      socket.once(getGameResponseEvent, async (result) => {
        this.$store.commit('env/setIsLoading', false);
        this.game = result.data;

        // 手機版若沒有對局會導回對戰首頁
        if (!this.game && this.$device.isMobile) {
          this.$router.push({name: this.$isJungo ? 'pvp-menu' : 'pvp-home'});
          return;
        }
        if (this.game) {
          this.isWebPvpHome = false;
          this.isPvpWait = false;
          this.bindGameInfo(this.game);
          await this.initialPlayer(this.game);
          this.initialTime('black');
          this.initialTime('white');
          this.runTimer();
          if (
            this.game.sgf.split(';').length <= 2 &&
            !this.game.winner &&
            !this.game.isPaused
          ) {
            this.isPassAnimation = true;
            setTimeout(() => {
              this.isLeave = true;
            }, 1000);
          } else {
            this.isLeave = true;
          }
        } else {
          // web版若沒有對局會在房間中開啟對戰選單
          this.isWebPvpHome = true;
          const prevQuickMatchSetting = localStorage.getItem(
            'pvp.latestQuickMatchSetting'
          );
          if (prevQuickMatchSetting) {
            this.selectedQuickMatchOption = prevQuickMatchSetting;
          } else {
            this.boardSize = 7;
          }
        }

        socket.startPingPong();
        // 落子
        socket.on(socket.ResponseEventEnum.PVP_MOVE, (result) => {
          this.isJudgeable = true;
          const data = result.data;

          if (this.offlineMessage) {
            this.offlineMessage();
            this.offlineMessage = null;
          }
          // 對手落子
          if (data.from !== this.userDetails.username) {
            this.clearTimer();
            const isPlayer = [this.game.black, this.game.white].includes(
              this.userDetails.username
            );
            if (isPlayer) {
              this.isPlayerLocked = false;
            }
            if (isPlayer || this.pvpGameHelper.node.children.length === 0) {
              this.pvpGameHelper.sgf = data.sgf;

              if (data.move === 'pass') {
                this.$Message.info(this.$t('對手虛手'));
              }
            } else {
              // 觀戰者手數不在最後一手時，不因為落子改變棋盤盤面
              const currentStep = this.pvpGameHelper.step;
              this.pvpGameHelper.loadSgf(data.sgf);
              this.pvpGameHelper.goto(currentStep);
            }
          }
          this.blackTime.mainTime = data.blackTime;
          this.blackTime.byoYomiTime = data.blackByoYomiTime;
          this.blackTime.byoYomiAmount = data.blackByoYomiAmount;
          this.blackTimeText = this.getTimeText(this.blackTime);
          this.whiteTime.mainTime = data.whiteTime;
          this.whiteTime.byoYomiTime = data.whiteByoYomiTime;
          this.whiteTime.byoYomiAmount = data.whiteByoYomiAmount;
          this.whiteTimeText = this.getTimeText(this.whiteTime);
        });
        // 貼圖
        socket.on(socket.ResponseEventEnum.PVP_RECEIVE_MESSAGE, (result) => {
          const data = result.data;
          if (data.from === this.userDetails.username) {
            this.lastStickerTime = Date.now();
            localStorage.setItem('lastStickerTime', this.lastStickerTime);
          }

          if (data.from === this.game.black) {
            this.blackStickerType = 'sticker';
            this.blackSticker = data.messageId;
          } else if (data.from === this.game.white) {
            this.whiteStickerType = 'sticker';
            this.whiteSticker = data.messageId;
          }
        });
        // 對局結束
        socket.on(socket.ResponseEventEnum.PVP_GAME_ENDED, (result) => {
          this.$store.commit('env/setIsLoading', false);
          const data = result.data;
          this.isPlayerLocked = true;
          const winner =
            data.result.substring(0, 2) === 'B+' ? 'black' : 'white';

          this.modal = winner === this.userColor ? 'win' : 'lose';
          this.$playSound(this.modal);
          BackEvent.addEvent(() => {
            this.goPvpHome();
          });

          this.isScorePanelShow = false;
          this.winningWayText = filters.winningWayText(
            data.result,
            this.game.komi,
            this.game.boardSize
          );
          this.game.winner = winner;
          this.$refs.chessboard.isModalMessageBoxShow = false;
          this.cancelSticker();
          this.clearTimer();
        });
        // 算輸贏請求
        socket.on(
          socket.ResponseEventEnum.PVP_RECEIVE_JUDGEMENT_INVITATION,
          (result) => {
            this.isJudgeable = true;
            const data = result.data;
            this.$store.commit('env/setIsLoading', false);
            this.isPlayerLocked = true;
            this.game.isJudging = true;
            this.clearTimer();

            if (data.from === this.userDetails.username) {
              this.modal = 'judgeWait';
            } else {
              this.modal = 'judge';
              BackEvent.addEvent(() => {
                this.refuseJudge();
              });
            }
          }
        );
        // 棋局尚未結束，無法算輸贏
        socket.on(socket.ResponseEventEnum.PVP_GAME_UNJUDGEABLE, (result) => {
          this.isJudgeable = true;
          this.$store.commit('env/setIsLoading', false);
          const data = result.data;

          if (data.from === this.userDetails.username) {
            if (data.influence) {
              const influenceArray = leelaUtils.getInfluenceArray(
                data.influence
              );
              influenceArray.forEach((row, y) => {
                row.forEach((mark, x) => {
                  if (mark === '.') {
                    this.pvpGameHelper.editable.board.addObject({
                      type: 'RedFilter',
                      x,
                      y,
                    });
                  }
                });
              });
              this.$Message.warning(
                this.$t('還沒下完，還不能算輸贏哦！'),
                1000 * 5
              );
            } else {
              this.$Message.warning(this.$t('棋局還沒結束哦！'), 1000 * 5);
            }
          }
          this.setTurn();
          this.runTimer();
        });
        // 拒絕算輸贏
        socket.on(
          socket.ResponseEventEnum.PVP_RECEIVE_JUDGEMENT_REFUSAL,
          (result) => {
            const data = result.data;
            this.game.isJudging = false;
            this.$store.commit('env/setIsLoading', false);
            if (data.from !== this.userDetails.username) {
              this.modal = 'refuseJudge';
              BackEvent.addEvent(() => {
                this.refuse2Judge();
              });
            }
            this.setTurn();
            this.runTimer();
          }
        );
        // 接受算輸贏，並取得對局結果
        socket.on(
          socket.ResponseEventEnum.PVP_RECEIVE_JUDGEMENT_CONFIRMATION,
          (result) => {
            const data = result.data;
            this.isJudgeConfirm = false;
            this.game.isJudging = true;
            this.$store.commit('env/setIsLoading', false);

            this.modal = '';
            this.paintInfluence(data.influence);
            this.isScorePanelShow = true;
            this.winningWayText = filters.winningWayText(
              data.result,
              this.game.komi,
              this.game.boardSize
            );
            clearTimeout(this.judgeWaitTimeoutTimer);

            BackEvent.addEvent(() => {
              this.refuseJudgeResult();
            });
          }
        );
        // 拒絕算輸贏結果
        socket.on(
          socket.ResponseEventEnum.PVP_RECEIVE_JUDGEMENT_RESULT_REFUSAL,
          () => {
            if (this.game.isJudging) {
              this.$Message.info(this.$t('有一方不同意輸贏結果'));
            }
            this.modal = '';
            this.isScorePanelShow = false;
            this.isJudgeConfirm = false;
            this.game.isJudging = false;
            this.pvpGameHelper.clearInfluenceMarkup();
            this.setTurn();
            this.runTimer();
            BackEvent.clearEvents();
            BackEvent.addEvent(() => {
              this.leave();
            });
          }
        );
        // 接受算輸贏結果
        socket.on(
          socket.ResponseEventEnum.PVP_RECEIVE_JUDGEMENT_RESULT_CONFIRMATION,
          (result) => {
            const data = result.data;
            this.isJudgeConfirm = data.from === this.userDetails.username;
          }
        );
        // 再來一局請求
        socket.on(
          socket.ResponseEventEnum.PVP_RECEIVE_REMATCH_INVITATION,
          (result) => {
            const data = result.data;
            if (data.from === this.userDetails.username) {
              this.modal = 'rematchWait';
            } else {
              BackEvent.addEvent(() => {
                this.refuseRematch();
              });
              this.modal = 'rematch';
            }
          }
        );
        // 接受再來一局
        socket.once(socket.ResponseEventEnum.PVP_GAME_STARTED, () => {
          this.modal = '';
          this.initialGame();
        });
        // 拒絕再來一局
        socket.once(
          socket.ResponseEventEnum.PVP_RECEIVE_REMATCH_REFUSAL,
          (result) => {
            const data = result.data;
            if (this.modal === 'rematchWait') {
              this.modal = '';
            }

            if (data.from !== this.userDetails.username) {
              BackEvent.addEvent(() => {
                this.$device.isMobile
                  ? this.$router.push({name: 'pvp-home'})
                  : this.onWebPvpHome();
              });
              this.modal = 'refuseRematch';
            } else {
              this.$device.isMobile
                ? this.$router.push({name: 'pvp-home'})
                : this.onWebPvpHome();
            }
          }
        );
        // 斷線
        socket.on(socket.ResponseEventEnum.PVP_DISCONNECT, () => {
          if (!['win', 'lose'].includes(this.modal)) {
            this.modal = '';
          }
          if (this.game.isJudging) {
            this.game.isJudging = false;
            this.isJudgeConfirm = false;
            this.isScorePanelShow = false;
            this.setTurn();
            this.runTimer();
            this.pvpGameHelper.clearInfluenceMarkup();
          }
        });
        // socket連線重新建立
        socket.on(socket.ResponseEventEnum.REGISTER_SUCCESSFULLY, () => {
          const sendEvent = socket.SendEventEnum.PVP_GET_UNFINISHED_GAME;
          socket.startPingPong();
          socket.send({
            action: 'pvp',
            pvpAction: sendEvent,
          });
        });
        // socket連線重新建立後，會重新取得對局狀態，並以此更新對局資訊
        socket.on(getGameResponseEvent, (result) => {
          const game = result.data;
          if (!game) {
            // 代表對局已結束
            this.shouldLeaveConfirm = false;
            this.$device.isMobile
              ? this.$router.push({name: 'pvp-home'})
              : this.$router.push('/');
            return;
          }
          this.$store.commit('env/setIsLoading', false);
          if (this.offlineMessage) {
            this.offlineMessage();
            this.offlineMessage = null;
          }

          this.game = game;
          this.bindGameInfo(this.game);
          this.pvpGameHelper.sgf = this.game.sgf;
          this.turnColor = this.pvpGameHelper.turnColor;
          this.setTurn();
          this.initialTime('black');
          this.initialTime('white');
          if (!this.game.isJudging) {
            this.isJudgeConfirm = false;
            this.isScorePanelShow = false;
          }
          if (!this.game.winner && !this.game.isJudging) {
            this.runTimer();
          }
        });
        // 落子error
        socket.onError(socket.SendEventEnum.PVP_MOVE, () => {
          this.$Message.error(this.$t('落子異常，刷新棋盤資訊中...'));
          setTimeout(() => {
            socket.send({
              action: 'pvp',
              pvpAction: socket.SendEventEnum.PVP_GET_UNFINISHED_GAME,
            });
          }, 1000);
        });
        // 算輸贏請求error
        socket.onError(socket.SendEventEnum.PVP_INVITE_JUDGEMENT, (result) => {
          this.isJudgeable = true;
          if (result && result.message === errorMapping.OPPONENT_DISCONNECTED) {
            this.$Message.error(this.$t('對方斷線，不能算輸贏'));
          }
        });
        // 網路斷線、不穩error
        socket.onError(socket.DefaultEventEnum.NO_PONG, () => {
          if (!this.offlineMessage) {
            this.offlineMessage = this.$Message.error(
              this.$t('請確認網路連線的狀態後再次嘗試'),
              false
            );
          }
        });
        // 未handle的預設錯誤
        socket.onError(socket.DefaultEventEnum.DEFAULT_ERROR, (result) => {
          if (result.message !== errorMapping.INCORRECT_ACTION) {
            this.$Message.error(
              this.$t('非預期的錯誤發生，請重新整理'),
              1000 * 5
            );
          }
        });
        socket.onRedirectRouter(() => {
          this.shouldLeaveConfirm = false;
        });
        socket.onLogout(() => {
          this.shouldLeaveConfirm = false;
        });
        socket.onAskIfLogoutOtherAccount(() => {
          this.shouldLeaveConfirm = false;
        });
      });
    },
    untilNextTick() {
      return new Promise((resolve) => {
        this.$nextTick(() => {
          resolve();
        });
      });
    },
    // 初始化棋盤
    initialBoardSize() {
      if (this.pvpGameHelper) {
        const sgf = `(;CA[big5]SZ[${this.boardSize}])`;
        this.pvpGameHelper.sgf = sgf;
      } else {
        this.chessboardKey++;
      }
    },
    // 清掉socket evnet
    clearSocketEvent() {
      this.clearTimer();
      if (this.offlineMessage) {
        this.offlineMessage();
        this.offlineMessage = null;
      }
      socket.endPingPong();
      socket.clearPvpEvents();
      socket.clearEvents([
        socket.DefaultEventEnum.NO_PONG,
        socket.DefaultEventEnum.DEFAULT_ERROR,
        socket.ResponseEventEnum.REGISTER_SUCCESSFULLY,
      ]);
    },
    // 發送表情貼圖
    sendSticker(id) {
      if (
        this.lastStickerTime &&
        Date.now() - this.lastStickerTime < 1000 * PvpConfig.stickerThrottle
      ) {
        this.$Message.info(
          this.$t('發言時間過近，請{v1}秒後再發言', {
            v1: PvpConfig.stickerThrottle,
          })
        );
        return;
      }
      socket.send({
        action: 'pvp',
        pvpAction: socket.SendEventEnum.PVP_SEND_MESSAGE,
        data: {
          gameId: this.game.gameId,
          messageId: id,
        },
      });
    },
    // 取消表情貼圖
    cancelSticker() {
      this.blackSticker = null;
      this.whiteSticker = null;
    },
    clearTimer() {
      clearInterval(this.blackTimer);
      clearInterval(this.whiteTimer);
      clearTimeout(this.checkOverTimeTimer);
      clearTimeout(this.onJudgeWaitTimeout);
      this.cancelPvpMatchAiTimeout();
    },
    // 用後端的資料綁定視圖參數
    bindGameInfo(game) {
      this.sgf = game.sgf;
      this.boardSize = game.boardSize;
      this.blackPlayer.name = game.blackNickName;
      this.blackPlayer.avatar = game.blackAvatar;
      this.blackPlayer.rank = game.blackRank;
      this.whitePlayer.name = game.whiteNickName;
      this.whitePlayer.avatar = game.whiteAvatar;
      this.whitePlayer.rank = game.whiteRank;

      this.blackTime.mainTime = game.blackTime;
      this.blackTime.byoYomiTime = game.blackByoYomiTime;
      this.blackTime.byoYomiAmount = game.blackByoYomiAmount;
      this.whiteTime.mainTime = game.whiteTime;
      this.whiteTime.byoYomiTime = game.whiteByoYomiTime;
      this.whiteTime.byoYomiAmount = game.whiteByoYomiAmount;
    },
    // 初始化時間
    initialTime(color, resetByoYomiTime) {
      const timeObject = this[color + 'Time'];
      // 還有基本時限
      if (timeObject.mainTime > 0) {
        const minute = Math.floor(timeObject.mainTime / 60);
        const second = Math.floor(timeObject.mainTime % 60);
        this[color + 'TimeText'] = `${minute
          .toString()
          .padStart(2, '0')}:${second.toString().padStart(2, '0')}`;
      }
      // 還有讀秒
      else {
        if (resetByoYomiTime) {
          timeObject.byoYomiTime = this.game.gameByoYomiTime;
        }
        this[color + 'TimeText'] = `${timeObject.byoYomiTime
          .toString()
          .padStart(2, '0')}${this.$t('秒')} / ${
          timeObject.byoYomiAmount
        }${this.$t('次')}`;
      }
    },
    // 跑時間timer
    runTimer(color = this.turnColor) {
      if (!['black', 'white'].includes(color)) {
        return;
      }
      if (this.game.winner || this.game.isJudging || this.game.isPaused) {
        return;
      }
      this.clearTimer();

      const opponentColor = color === 'black' ? 'white' : 'black';
      this.initialTime(opponentColor, true);

      const timeObject = this[color + 'Time'];
      this[color + 'Timer'] = setInterval(() => {
        // 還有基本時限
        if (timeObject.mainTime > 0) {
          timeObject.mainTime -= 1;
          this[color + 'TimeText'] = this.getTimeText(timeObject);
        }
        // 還有讀秒
        else if (timeObject.byoYomiTime > 0) {
          timeObject.byoYomiTime -= 1;
          this[color + 'TimeText'] = this.getTimeText(timeObject);
          if (timeObject.byoYomiTime <= 10) {
            this[color + 'Sticker'] = timeObject.byoYomiTime;
            this[color + 'StickerType'] = 'number';
            // 最後一次讀秒語音，為2.X秒的音效，需蓋掉10~8秒的讀秒
            if (!this.isLastAmountSoundPlaying || timeObject.byoYomiTime <= 7) {
              this.$playSound(`${timeObject.byoYomiTime}`);
            }
          }
        } else if (timeObject.byoYomiAmount > 1) {
          timeObject.byoYomiAmount -= 1;
          if (timeObject.byoYomiAmount === 1) {
            this.$playSound(`${color}-last`);
            this.isLastAmountSoundPlaying = true;
            setTimeout(() => {
              this.isLastAmountSoundPlaying = false;
            }, 1000 * 3);
          }
          timeObject.byoYomiTime = this.game.gameByoYomiTime;
          this[color + 'TimeText'] = this.getTimeText(timeObject);
          this[color + 'Sticker'] = null;
          this[color + 'StickerType'] = 'sticker';
        } else {
          this.clearTimer();
          this.blackSticker = null;
          this.blackStickerType = 'sticker';
          this.whiteSticker = null;
          this.whiteStickerType = 'sticker';
          // 自己判自己超時，馬上進行判決
          if (this.game[color] === this.userDetails.username) {
            socket.send({
              action: 'pvp',
              pvpAction: socket.SendEventEnum.PVP_CHECK_OVERTIME,
              data: {
                gameId: this.game.gameId,
              },
            });
          }
          // 幫對手判超時，必須超過3秒才判決
          else if (this.game[color] !== this.userDetails.username) {
            this.checkOverTimeTimer = setTimeout(() => {
              socket.send({
                action: 'pvp',
                pvpAction: socket.SendEventEnum.PVP_CHECK_OVERTIME,
                data: {
                  gameId: this.game.gameId,
                },
              });
            }, 1000 * PvpConfig.opponentOverTimeBuffer);
          }
        }
      }, 1000);
    },
    // 投降
    resign() {
      this.modal = '';
      this.$store.commit('env/setIsLoading', true);
      socket.send({
        action: 'pvp',
        pvpAction: socket.SendEventEnum.PVP_RESIGN,
        data: {
          gameId: this.game.gameId,
        },
      });
    },
    // 申請算輸贏
    inviteJudgement() {
      if (this.turnColor !== this.userColor) {
        this.$Message.error(this.$t('只有落子方可以申請算輸贏'));
        return;
      }
      const judgeStepLimit = PvpConfig.judgeStepLimit[this.boardSize];
      if (this.pvpGameHelper.step < judgeStepLimit) {
        this.$Message.warning(
          `${judgeStepLimit}${this.$t('手以上才能申請算輸赢哦')}`
        );
        return;
      }
      if (!this.isJudgeable) {
        return;
      }
      this.isJudgeable = false;
      this.isPlayerLocked = true;
      this.$store.commit('env/setIsLoading', true);
      socket.send({
        action: 'pvp',
        pvpAction: socket.SendEventEnum.PVP_INVITE_JUDGEMENT,
        data: {
          gameId: this.game.gameId,
        },
      });
    },
    opponentCountGameTimeout() {
      const opponentUsername =
        this.game.black === this.userDetails.username
          ? this.game.white
          : this.game.black;
      socket.send({
        action: 'pvp',
        pvpAction: socket.SendEventEnum.PVP_REFUSE_JUDGEMENT_RESULT,
        data: {
          gameId: this.game.gameId,
          refusedBy: opponentUsername,
        },
      });
    },
    // 接受算輸贏
    acceptJudge() {
      BackEvent.popEvent();
      this.modal = '';
      socket.send({
        action: 'pvp',
        pvpAction: socket.SendEventEnum.PVP_ACCEPT_JUDGEMENT,
        data: {
          gameId: this.game.gameId,
        },
      });
    },
    // 拒絕算輸贏
    refuseJudge() {
      BackEvent.popEvent();
      this.modal = '';
      socket.send({
        action: 'pvp',
        pvpAction: socket.SendEventEnum.PVP_REFUSE_JUDGEMENT,
        data: {
          gameId: this.game.gameId,
          refusedBy: this.userDetails.username,
        },
      });
      this.setTurn();
      this.runTimer();
    },
    onJudgeWaitTimeout() {
      this.judgeWaitTimeoutTimer = setTimeout(() => {
        this.modal = 'refuseJudge';
      }, 1000 * PvpConfig.judgeWaitTimeoutBuffer);
    },
    // 接受算輸贏結果
    acceptJudgeResult() {
      BackEvent.popEvent();
      this.modal = '';
      socket.send({
        action: 'pvp',
        pvpAction: socket.SendEventEnum.PVP_ACCEPT_JUDGEMENT_RESULT,
        data: {
          gameId: this.game.gameId,
        },
      });
    },
    // 拒絕進行算輸贏
    refuse2Judge() {
      BackEvent.popEvent();
      this.modal = '';
    },
    // 拒絕算輸贏結果
    refuseJudgeResult() {
      BackEvent.popEvent();
      this.modal = '';
      this.isScorePanelShow = false;
      socket.send({
        action: 'pvp',
        pvpAction: socket.SendEventEnum.PVP_REFUSE_JUDGEMENT_RESULT,
        data: {
          gameId: this.game.gameId,
          refusedBy: this.userDetails.username,
        },
      });
    },
    cancelConfirm() {
      BackEvent.popEvent();
      BackEvent.addEvent(() => {
        this.leave();
      });
      this.modal = '';
    },
    inviteRematch() {
      BackEvent.popEvent();
      socket.send({
        action: 'pvp',
        pvpAction: socket.SendEventEnum.PVP_INVITE_REMATCH,
        data: {
          gameId: this.game.gameId,
        },
      });
    },
    // 接受再來一局
    acceptRematch() {
      this.$store.commit('env/setIsLoading', true);
      this.modal = '';
      socket.send({
        action: 'pvp',
        pvpAction: socket.SendEventEnum.PVP_ACCEPT_REMATCH,
        data: {
          gameId: this.game.gameId,
        },
      });
    },
    // 拒絕再來一局
    refuseRematch() {
      BackEvent.popEvent();
      this.modal = '';
      socket.send({
        action: 'pvp',
        pvpAction: socket.SendEventEnum.PVP_REFUSE_REMATCH,
        data: {
          gameId: this.game.gameId,
        },
      });
      if (!this.$device.isMobile) this.initialBoardSize();
    },
    // 對局結束modal點擊事件
    onResultModalAction(event) {
      if (event === 'rematch') {
        this.inviteRematch();
      } else if (event === 'return') {
        this.$device.isMobile ? this.goPvpHome() : this.onWebPvpReturn();
      } else if (event === 'reviewGame') {
        this.$router.push({
          name: 'kifu-preview',
          params: {createdTime: this.game.gameId, gameMode: 'pvp'},
          query: {isGameOverReivewed: true},
        });
      }
    },
    // 停止等待好友並離開房間
    waitLeave() {
      BackEvent.clearEvents();
      this.shouldLeaveConfirm = false;
      this.modal = '';
      this.onWebPvpHome();
      this.goPvpHome();
    },
    // 投降並離開房間
    resignAndLeave() {
      BackEvent.clearEvents();
      this.shouldLeaveConfirm = false;
      this.resign();
      this.goPvpHome();
    },
    // about chessboard
    onCapCountChange(captureCount) {
      this.capCount = captureCount;
    },
    onTurnColorChange(turnColor) {
      this.turnColor = turnColor;
    },
    onStepTypeChange(stepType) {
      this.stepType = stepType;
    },
    onCurrentStepChange(currentStep) {
      this.currentStep = currentStep;
    },
    onMaxStepChange(maxStep) {
      this.maxStep = maxStep;
    },
    onChessboardToolbarModeChange(chessboardToolbarMode) {
      this.chessboardToolbarMode = chessboardToolbarMode;
    },
    // about chessboardtoolbar
    onToolbarClick(event) {
      if (event === 'pass' && this.turnColor !== this.userColor) {
        return this.$Message.warning(this.$t('還沒輪到你哦'));
      }
      this.$refs.chessboard.onToolbarClick(event);
    },
    moveChess(direction) {
      this.$refs.chessboard.moveChess(direction);
    },
    checkAnswerTab(value) {
      this.$refs.chessboard.checkAnswerTab(value);
    },
    stepChange(step) {
      this.$refs.chessboard.stepChange(step);
    },
    // 關閉進場動畫
    destoryPassAnimation() {
      this.isPassAnimation = false;
    },
    // 畫面左上角返回
    leave() {
      BackEvent.popEvent();
      if (this.modal) {
        BackEvent.addEvent(() => {
          this.leave();
        });
        this.$Message.error(this.$t('請先完成目前的操作'));
        return;
      }
      if (this.isWebPvpHome) {
        this.$router.push('/');
      } else if (this.game && this.game.winner) {
        this.$router.go(-1);
      } else {
        BackEvent.addEvent(() => {
          this.cancelConfirm();
        });
        this.modal = this.isPvpWait ? 'waitLeave' : 'leave';
      }
    },
    goPvpHome() {
      BackEvent.clearEvents();
      this.$device.isMobile
        ? this.$router.push({name: this.$isJungo ? 'pvp-menu' : 'pvp-home'})
        : this.$router.push('/');
    },
    goSetting() {
      BackEvent.addEvent(() => {
        return this.$router.push({name: 'pvp'});
      });
      return this.$device.isMobile
        ? this.$router.push({name: 'roomSetting'})
        : (this.isSettingShow = true);
    },
    onModalLeave() {
      this.$device.isMobile ? this.goPvpHome() : this.onWebPvpReturn();
    },
    // 取得時間字串的工具函式
    getTimeText(timeObject) {
      // 還有基本時限
      if (timeObject.mainTime > 0) {
        const minute = Math.floor(timeObject.mainTime / 60);
        const second = Math.floor(timeObject.mainTime % 60);
        return `${minute.toString().padStart(2, '0')}:${second
          .toString()
          .padStart(2, '0')}`;
      }
      // 讀秒階段
      return `${timeObject.byoYomiTime.toString().padStart(2, '0')}${this.$t(
        '秒'
      )} / ${timeObject.byoYomiAmount}${this.$t('次')}`;
    },
    paintInfluence(influence) {
      const influenceArray = leelaUtils.getInfluenceArray(influence);

      influenceArray.forEach((row, y) => {
        row.forEach((mark, x) => {
          if (mark == 'o' || mark == 'b') {
            this.pvpGameHelper.addObject({type: 'mini', x, y, c: -1});
          } else if (mark == 'x' || mark == 'w') {
            this.pvpGameHelper.addObject({type: 'mini', x, y, c: 1});
          } else if (mark == '.') {
            this.pvpGameHelper.addObject({type: 'RedFilter', x, y});
          }
        });
      });
    },

    // web快速配對
    onQuickMatchOptionClick(option) {
      this.selectedQuickMatchOption = option;
      localStorage.setItem(
        'pvp.latestQuickMatchSetting',
        this.selectedQuickMatchOption
      );
    },
    onWebPvpHome() {
      this.lobbyId = null;
      this.isPvpWait = false;
      this.isWebPvpHome = true;
      socket.once(socket.ResponseEventEnum.PVP_GAME_STARTED, () => {
        this.modal = '';
        this.initialGame();
      });
    },
    onPvpWait(lobbyId) {
      this.lobbyId = lobbyId;
      this.isPvpWait = true;
      this.isWebPvpHome = false;
    },
    onWebPvpReturn() {
      this.modal = '';
      this.isNoMatchShow = false;
      this.clearSocketEvent();
      this.initialBoardSize();
      this.onWebPvpHome();
    },
    onBoardSizeChange(boardSize) {
      this.boardSize = boardSize;
      this.initialBoardSize();
    },
    closeQuickMatch() {
      this.deleteLobby(this.lobbyId);
      this.lobbyId = null;
      this.cancelPvpMatchAiTimeout();
    },
    async getQuickMatch() {
      if (this.isLoading) {
        return;
      }
      this.$store.commit('env/setIsLoading', true);
      const params = {
        boardSize: this.boardSize,
        hasHandicap: this.hasHandicap,
      };
      try {
        const lobbyId = await this.quickMatchByBoardSize(params);
        if (lobbyId === 'none') {
          const data = {
            boardSize: this.boardSize,
            time: this.time * 60,
            byoYomiTime: this.byoYomiTime,
            byoYomiAmount: this.byoYomiAmount,
            isPublic: true,
            hasHandicap: this.hasHandicap,
          };
          this.createLobby(data);
          this.$store.commit('env/setIsLoading', false);
          this.isQuickMatchShow = true;
        } else if (lobbyId) {
          this.isQuickMatchShow = false;
          this.enterLobby(lobbyId);
          this.$store.commit('env/setIsLoading', true);
        }
      } catch (error) {
        this.isQuickMatchShow = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pvp {
  background-color: $bgsection;

  .invisible {
    visibility: hidden;
  }
  .remark {
    color: $font-grayscale-3;
  }
  .wrap-pvp {
    top: 50%;
    transform: translateY(-50%);
    @media screen and (min-width: 768px) {
      top: 0;
      transform: translateY(0);
      height: 100%;
    }
  }
  .wrap-right {
    width: 100%;
    @media screen and (min-width: 768px) {
      width: auto;
    }
    .wrap-operate-panel {
      width: 100%;
      @media screen and (min-width: 768px) {
        min-width: 289px;
        width: 289px;
        margin: 0;
        height: 100%;
        border-radius: $rounded-md;
        padding: 16px;
      }
      @media screen and (min-width: 1440px) {
        min-width: 446px;
        width: 446px;
      }
      .operate-panel {
        @media screen and (min-width: 768px) {
          background: $bgsection;
          height: 100%;
          border-radius: $rounded-md;
        }
        .wrap-victory-condition {
          @media screen and (min-width: 768px) {
            padding: 5px 0;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
          }
        }
      }
    }
    .wrap-btn-tab {
      .btn {
        width: 50%;
      }
    }
  }
}
</style>
