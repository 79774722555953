import socket from '@/lib/socket/socket.js';
import errorMapping from '@/constant/error';

const mixinObject = {
  computed: {
    isLogin() {
      return this.$store.getters['user/isLogin'];
    },
  },
  methods: {
    enterLobby(lobbyId) {
      if (!this.isLogin) {
        this.$Message.error(this.$t('請先登入，才能夠加入對局喔！'), 3000);
        this.$router.push({name: 'login'});
        return;
      }
      socket.send({
        action: 'pvp',
        pvpAction: socket.SendEventEnum.PVP_ENTER_LOBBY,
        data: {
          lobbyId,
        },
      });

      socket.once(socket.ResponseEventEnum.PVP_GAME_STARTED, () => {
        this.$store.commit('env/setIsLoading', false);
        this.$router.push({name: 'pvp'});
      });
      // 加入等待室 error
      socket.onError(socket.SendEventEnum.PVP_ENTER_LOBBY, (result) => {
        this.$store.commit('env/setIsLoading', false);
        const message = result.message;
        let error = null;
        if (message === errorMapping.NO_PVP_LOBBY) {
          error = this.$t('房間不存在');
        } else if (message === errorMapping.LOBBY_OWNER_DISCONNECTED) {
          error = this.$t('此房間已關閉');
        } else if (message === errorMapping.SELF_CREATED_LOBBY) {
          error = this.$t('無法加入自己創建的房間');
        }
        if (error) {
          this.$router.push({
            name: 'home',
            query: {
              error,
            },
          });
        } else {
          this.$router.push({name: 'home'});
        }
      });
    },
  },
  beforeDestroy() {
    this.$store.commit('env/setIsLoading', false);
    socket.clearEvents([
      socket.ResponseEventEnum.PVP_GAME_STARTED,
      socket.SendEventEnum.PVP_ENTER_LOBBY,
    ]);
  },
};

export default mixinObject;
