<template>
  <div
    class="pvp-wait overflow-auto h-100 w-100 d-flex flex-column"
    :class="{'jungo-pvp-wait': $isJungo}"
  >
    <base-header
      v-if="$device.isMobile"
      :title="$t('邀請對局')"
      :is-title-bold="true"
      :back-on-click-left="false"
      :left-arrow="true"
      @on-click-left="exit"
    ></base-header>
    <div
      class="d-flex flex-column justify-content-center align-items-center flex-fill p-3"
    >
      <div class="pvp-wait-title font-weight-bold">
        {{ $t('邀請好友下棋') }}
      </div>
      <div class="pvp-wait-sub-title">
        {{ $t('點擊分享連結按鈕邀請好友一起對弈') }}
      </div>
      <div class="info-wrapper w-100 mt-3 p-3 p-md-0 rounded-10">
        <versus
          :avatar="userDetails.avatar"
          :nickname="userDetails.nickName"
        ></versus>
        <div class="setting-wrapper mt-2 p-2 rounded-10">
          <div
            class="setting-title w-100 text-20 font-weight-bold d-flex align-items-center justify-content-center rounded-10"
          >
            {{ $t('對局條件') }}
          </div>
          <div class="mb-2 mt-3 text-18">
            <div class="d-flex">
              <div class="title ml-4">{{ $t('棋盤') }}</div>
              <div class="ml-5">{{ $t('{v1}路', {v1: lobby.boardSize}) }}</div>
            </div>
            <div class="d-flex">
              <div class="title ml-4">{{ $t('時限') }}</div>
              <div class="ml-5">
                {{ $t('{v1}分鐘', {v1: lobby.time / 60}) }}
              </div>
            </div>
            <div class="d-flex">
              <div class="title ml-4">{{ $t('讀秒') }}</div>
              <div class="ml-5">
                {{
                  $t('{v1}秒', {v1: lobby.byoYomiTime}) +
                  $t('{v1}次', {v1: lobby.byoYomiAmount})
                }}
              </div>
            </div>
            <div class="d-flex">
              <div class="title ml-4">{{ $t('手合') }}</div>
              <div class="ml-5">
                {{ lobby.hasHandicap ? $t('有') : $t('無') }}
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-wrap justify-content-center mt-2">
          <b-button
            class="exit-btn nowrap mr-2 font-weight-bold"
            variant="secondary"
            size="lg"
            @click="exit"
            >{{ $t('離開') }}</b-button
          >
          <b-button
            class="flex-fill font-weight-bold"
            variant="primary"
            size="fixed-lg"
            @click="share"
            >{{ $device.isMobile ? $t('分享連結') : $t('複製連結') }}</b-button
          >
        </div>
      </div>
    </div>

    <modal-confirm
      v-model="isLeaveConfirmModalShow"
      @confirm="leave"
      @cancel="closeModal"
    >
      {{ $t('確定要離開對局？') }}
    </modal-confirm>
  </div>
</template>

<script>
// import {clientVersion, stage} from '@/constant/env';
import {Device} from '@capacitor/device';
import BaseHeader from '@/components/Base/BaseHeader';
import Versus from '@/components/Base/Versus';
import ModalConfirm from '@/components/Modal/ModalConfirm';
import copyToClipboard from '@/lib/base/copyToClipboard.js';
import socket from '@/lib/socket/socket.js';
import BackEvent from '@/lib/base/backEvent.js';
import rankList from '@/json/rank.json';

export default {
  components: {
    BaseHeader,
    Versus,
    ModalConfirm,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLeaveConfirmModalShow: false, // 確認離開的confirm modal
      boardSize: 19,
      lobby: {
        boardSize: null,
        time: null,
        byoYomiTime: null,
        byoYomiAmount: null,
        hasHandicap: null,
      },
      lobbyId: null,
    };
  },
  computed: {
    userDetails() {
      return this.$store.getters['user/userData'];
    },
  },
  created() {
    this.lobbyId = this.$route.params.id || this.id;
    const lobby = this.$store.state.pvp.lobby;
    BackEvent.addEvent(() => {
      this.exit();
    });
    if (!lobby) {
      BackEvent.popEvent();
      this.$router.push({name: 'pvp-menu'});
      return;
    }

    this.lobby = lobby;
    socket.once(socket.ResponseEventEnum.PVP_GAME_STARTED, () => {
      this.lobbyId = null;
      this.$router.push({name: 'pvp'});
    });

    // socket連線重新建立
    socket.once(socket.ResponseEventEnum.REGISTER_SUCCESSFULLY, () => {
      socket.clearEvents([socket.ResponseEventEnum.PVP_EXIST_PVP_GAME]);
      socket.once(socket.ResponseEventEnum.PVP_EXIST_PVP_GAME, (result) => {
        const data = result.data;
        if (data) {
          this.lobbyId = null;
          this.$router.push({name: 'pvp'});
        }
      });
      socket.send({
        action: 'pvp',
        pvpAction: socket.SendEventEnum.PVP_GET_UNFINISHED_GAME,
      });
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.lobbyId) {
      socket.send({
        action: 'pvp',
        pvpAction: socket.SendEventEnum.PVP_LEAVE_LOBBY,
      });
    }
    socket.clearEvents([
      socket.ResponseEventEnum.PVP_GAME_STARTED,
      socket.ResponseEventEnum.PVP_EXIST_PVP_GAME,
      socket.ResponseEventEnum.REGISTER_SUCCESSFULLY,
    ]);
    next();
  },
  methods: {
    exit() {
      BackEvent.popEvent();
      BackEvent.addEvent(() => {
        this.closeModal();
      });
      this.isLeaveConfirmModalShow = true;
    },
    async share() {
      const deviceInfo = await Device.getInfo();
      if (deviceInfo.platform === 'web') {
        // let url;
        // if (stage === 'prod') {
        //   const version = clientVersion.replace('hjj-', '').replace('hjj', '');
        //   url = `https://heijiajia-${version}.app.link/pvp?pvpId=${this.lobbyId}`;
        // } else {
        //   url = `${window.location.origin}/?pvpId=${this.lobbyId}`;
        // }
        // 純棋沒有手機版，暫不需要 branch.io
        const url = `${window.location.origin}/?pvpId=${this.lobbyId}`;
        copyToClipboard(url);
        this.$Message.success(this.$t('複製成功'));
      } else {
        const name = this.userDetails.nickName;
        const rank = rankList[this.userDetails.rank].label;
        const textInfo = `${name} (${rank}) ${this.$t(
          '在黑嘉嘉圍棋教室等你一決高下～'
        )}`;

        const boardSize = this.$t('{v1}路', {v1: this.lobby.boardSize});
        const minutes = this.$t('{v1}分鐘', {v1: this.lobby.time / 60});
        const time =
          this.$t('讀秒') +
          this.$t('{v1}秒', {v1: this.lobby.byoYomiTime}) +
          this.$t('{v1}次', {v1: this.lobby.byoYomiAmount});
        const hasHandicap = this.lobby.hasHandicap
          ? this.$t('讓子')
          : this.$t('無讓子');

        const textRule = `${this.$t(
          '對局條件'
        )}：${boardSize}-${minutes}-${time}（${hasHandicap}）`;

        const shareText = [textInfo, textRule].join('\n');
        this.$JShare.sharePvp(this.lobbyId, shareText);
      }
    },
    leave() {
      BackEvent.popEvent();
      this.$device.isMobile
        ? this.$router.push({name: 'pvp-menu'})
        : this.$emit('pvp-wait-close');
    },
    closeModal() {
      BackEvent.popEvent();
      BackEvent.addEvent(() => {
        this.exit();
      });
      this.isLeaveConfirmModalShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.pvp-wait {
  color: $font-grayscale-1;
  .pvp-wait-title {
    font-size: 28px;
    @media screen and (min-width: 768px) {
      font-size: 20px;
      line-height: 34px;
    }
  }
  .pvp-wait-sub-title {
    font-size: 16px;
    @media screen and (min-width: 768px) {
      font-size: 12px;
      line-height: 20px;
    }
  }
  .info-wrapper {
    background-color: $bgsection;
    text-align: center;
    .setting-wrapper {
      background-color: $white;
      .setting-title {
        height: 45px;
        background-color: $bgsection;
      }
    }
  }
  &.jungo-pvp-wait {
    .info-wrapper {
      .setting-wrapper {
        color: $font-normal;
      }
      .title {
        width: 72px;
        text-align: left;
      }
    }
    .exit-btn {
      margin-bottom: 0;
      @media screen and (min-width: 768px) {
        margin-bottom: 8px;
      }
      @media screen and (min-width: 1440px) {
        margin-bottom: 0;
      }
    }
  }
}
</style>
