<template>
  <div class="panel-timer rounded-10 p-3">
    <div class="text-center test-16 mb-2 d-flex align-items-center justify-content-center">
      <slot name="content"></slot>
    </div>
    <div class="d-flex justify-content-center my-2">
      <b-progress height="2px" :value="percentage" class="w-100"></b-progress>
    </div>
    <div class="text-center">
      <slot name="button"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sec: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      nowSec: 0,
      totalSec: 10,
      timer: null,
    };
  },
  computed: {
    percentage() {
      return Math.ceil((this.nowSec / this.totalSec) * 100);
    },
  },
  created() {
    this.startTimer();
  },
  methods: {
    startTimer() {
      this.nowSec = this.sec;
      this.totalSec = this.sec;
      this.timer = setInterval(() => {
        if (this.nowSec === 0) {
          clearInterval(this.timer);
          this.$emit('close');
        } else {
          this.nowSec -= 1;
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-timer {
  border: 3px solid $secondary;
  background-color: $white;
}
</style>
