import socket from '@/lib/socket/socket.js';
import pvpService from '@/services/pvp';
import ERROR from '@/constant/error';
import ImageUserCircle from '@/assets/img/pvp/user-circle.svg';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

const mixinObject = {
  data() {
    return {
      sec: 60, // 快速配對起始秒
      nowSec: 0, // 快速配對計時秒
      ImageUserCircle,
      isQuickMatchShow: false, // 等待快速配對modal
      isNoMatchShow: false, // 快速配對沒有匹配到modal
    };
  },
  computed: {
    openAiMatch() {
      const now = dayjs().format('YYYY-MM-DDHH:mm:ss');
      const nowDate = dayjs().format('YYYY-MM-DD');
      return dayjs(now).isBetween(`${nowDate}9:00:00`, `${nowDate}23:59:59`);
    },
  },
  methods: {
    updateNowSec(nowSec) {
      this.nowSec = nowSec;
    },
    async quickMatchByBoardSize({boardSize, hasHandicap}) {
      const params = {
        boardSize,
        hasHandicap,
      };
      try {
        const lobbyId = await pvpService.quickMatch(params);
        return lobbyId;
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message === ERROR.UNFINISHED_GAME
        ) {
          this.MessageHelper.error(this.$t('仍有進行中的對局，無法配對'));
        } else {
          this.MessageHelper.defaultError(error.response.status);
        }
        return error;
      }
    },
    deleteLobby(lobbyId) {
      const data = {lobbyId};
      socket.send({
        action: 'pvp',
        pvpAction: socket.SendEventEnum.PVP_DELETE_LOBBY,
        data,
      });
      socket.once(
        socket.ResponseEventEnum.PVP_DELETE_LOBBY_SUCCESSFULLY,
        () => {
          this.isQuickMatchShow = false;
          this.isNoMatchShow = false;
        }
      );
      socket.onError(socket.SendEventEnum.PVP_DELETE_LOBBY, () => {
        this.isQuickMatchShow = false;
        this.isNoMatchShow = false;
      });
    },
    createLobby({
      boardSize,
      time,
      byoYomiTime,
      byoYomiAmount,
      isPublic,
      hasHandicap,
    }) {
      const data = {
        boardSize,
        time,
        byoYomiTime,
        byoYomiAmount,
        isPublic,
        hasHandicap,
      };
      socket.send({
        action: 'pvp',
        pvpAction: socket.SendEventEnum.PVP_CREATE_LOBBY,
        data,
      });
      socket.once(
        socket.ResponseEventEnum.PVP_CREATE_LOBBY_SUCCESSFULLY,
        (result) => {
          this.lobbyId = result.data.lobbyId;
          socket.once(socket.ResponseEventEnum.PVP_GAME_STARTED, () => {
            this.isQuickMatchShow = false;
            this.$router.push({
              name: 'pvp',
            });
          });
        }
      );
      if (this.openAiMatch) this.pvpMatchAiAfterTimeout();
    },
  },
  beforeDestroy() {
    socket.clearEvents([
      socket.ResponseEventEnum.PVP_CREATE_LOBBY_SUCCESSFULLY,
      socket.ResponseEventEnum.PVP_DELETE_LOBBY_SUCCESSFULLY,
      socket.ResponseEventEnum.PVP_GAME_STARTED,
    ]);
  },
};

export default mixinObject;
